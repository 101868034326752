export const ALL_CATEGORY_REQUEST = 'ALL_CATEGORY_REQUEST'
export const ALL_CATEGORY_SUCCESS = 'ALL_CATEGORY_SUCCESS'
export const ALL_CATEGORY_FAIL = 'ALL_CATEGORY_FAIL'

export const NEW_CATEGORY_REQUEST = 'NEW_CATEGORY_REQUEST'
export const NEW_CATEGORY_SUCCESS = 'NEW_CATEGORY_SUCCESS'
export const NEW_CATEGORY_RESET = 'NEW_CATEGORY_RESET'
export const NEW_CATEGORY_FAIL = 'NEW_CATEGORY_FAIL'

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_RESET = 'UPDATE_CATEGORY_RESET'
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL'

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_RESET = 'DELETE_CATEGORY_RESET'
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL'

export const CATEGORY_DETAILS_REQUEST = 'CATEGORY_DETAILS_REQUEST'
export const CATEGORY_DETAILS_SUCCESS = 'CATEGORY_DETAILS_SUCCESS'
export const CATEGORY_DETAILS_FAIL = 'CATEGORY_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
