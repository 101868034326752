export const ALL_BRANDS_REQUEST = 'ALL_BRANDS_REQUEST'
export const ALL_BRANDS_SUCCESS = 'ALL_BRANDS_SUCCESS'
export const ALL_BRANDS_FAIL = 'ALL_BRANDS_FAIL'
export const NEW_BRAND_REQUEST = 'NEW_BRAND_REQUEST'
export const NEW_BRAND_SUCCESS = 'NEW_BRAND_SUCCESS'
export const NEW_BRAND_RESET = 'NEW_BRAND_RESET'
export const NEW_BRAND_FAIL = 'NEW_BRAND_FAIL'
export const DELETE_BRAND_REQUEST = 'DELETE_BRAND_REQUEST'
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS'
export const DELETE_BRAND_RESET = 'DELETE_BRAND_RESET'
export const DELETE_BRAND_FAIL = 'DELETE_BRAND_FAIL'
export const UPDATE_BRAND_REQUEST = 'UPDATE_BRAND_REQUEST'
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS'
export const UPDATE_BRAND_RESET = 'UPDATE_BRAND_RESET'
export const UPDATE_BRAND_FAIL = 'UPDATE_BRAND_FAIL'
export const BRAND_DETAILS_REQUEST = 'BRAND_DETAILS_REQUEST'
export const BRAND_DETAILS_SUCCESS = 'BRAND_DETAILS_SUCCESS'
export const BRAND_DETAILS_FAIL = 'BRAND_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
