import axios from "axios";
import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	REGISTER_USER_REQUEST,
	REGISTER_USER_SUCCESS,
	REGISTER_USER_FAIL,
	LOAD_USER_REQUEST,
	LOAD_USER_SUCCESS,
	LOAD_USER_FAIL,
	UPDATE_PROFILE_REQUEST,
	UPDATE_PROFILE_SUCCESS,
	UPDATE_PROFILE_FAIL,
	UPDATE_PASSWORD_REQUEST,
	UPDATE_PASSWORD_SUCCESS,
	UPDATE_PASSWORD_FAIL,
	FORGOT_PASSWORD_REQUEST,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAIL,
	NEW_PASSWORD_REQUEST,
	NEW_PASSWORD_SUCCESS,
	NEW_PASSWORD_FAIL,
	ALL_USERS_REQUEST,
	ALL_USERS_SUCCESS,
	ALL_USERS_FAIL,
	USER_DETAILS_REQUEST,
	USER_DETAILS_SUCCESS,
	USER_DETAILS_FAIL,
	UPDATE_USER_REQUEST,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAIL,
	DELETE_USER_REQUEST,
	DELETE_USER_SUCCESS,
	DELETE_USER_FAIL,
	LOGOUT_SUCCESS,
	LOGOUT_FAIL,
	SUBSCRIPTION_REQUEST,
	SUBSCRIPTION_SUCCESS,
	SUBSCRIPTION_FAIL,
	MESSAGE_REQUEST,
	MESSAGE_FAIL,
	MESSAGE_SUCCESS,
	CLEAR_ERRORS,
} from "../constants/userConstants";

// Login
const setTokenCookie = (token) => {
	// Calculate expiration date
	const expirationDate = new Date();
	expirationDate.setDate(expirationDate.getDate() + 7);

	// Format the cookie string
	const cookieValue = `token=${token}; expires=${expirationDate.toUTCString()}; path=/`;

	// Set the cookie
	document.cookie = cookieValue;
};
export const login = (email, password) => async (dispatch) => {
	try {
		console.log("OK");
		dispatch({ type: LOGIN_REQUEST });
		const token = localStorage.getItem('token');
		const config = {
			headers: {
				"Authorization": token
			},
		};

		const { data } = await axios.post(
			`https://api.lagha.shop/api/login`,
			{ email, password },
			config
		);
		localStorage.setItem("token", data?.token)
		setTokenCookie(data?.token)
		dispatch({
			type: LOGIN_SUCCESS,
			payload: data.user,
		});
	} catch (error) {
		dispatch({
			type: LOGIN_FAIL,
			payload: error.response.data,
		});
	}
};

// Register user
export const register = (name, email, password) => async (dispatch) => {
	try {
		dispatch({ type: REGISTER_USER_REQUEST });

		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};

		const { data } = await axios.post(
			`https://api.lagha.shop/api/register`,
			{ name, email, password },
			config
		);

		dispatch({
			type: REGISTER_USER_SUCCESS,
			payload: data.user,
		});
	} catch (error) {
		dispatch({
			type: REGISTER_USER_FAIL,
			payload: error.response.data,
		});
	}
};

// Load user
export const loadUser = () => async (dispatch) => {
	try {
		dispatch({ type: LOAD_USER_REQUEST });
		const token = localStorage.getItem('token');
		const config = {
			headers: {
				"Authorization": token
			},
		};

		const { data } = await axios.get("https://api.lagha.shop/api/me", config);

		dispatch({
			type: LOAD_USER_SUCCESS,
			payload: data?.user,
		});
	} catch (error) {
		dispatch({
			type: LOAD_USER_FAIL,
			payload: error?.response?.data?.message,
		});
	}
};

// Logout user
export const logout = () => async (dispatch) => {
	try {
		await axios.get("/api/logout");

		dispatch({
			type: LOGOUT_SUCCESS,
		});
	} catch (error) {
		dispatch({
			type: LOGOUT_FAIL,
			payload: error.response.data.message,
		});
	}
};

// Update profile
export const updateProfile = (userData) => async (dispatch) => {
	try {
		dispatch({ type: UPDATE_PROFILE_REQUEST });

		const token = localStorage.getItem('token');
		const config = {
			headers: {
				"Content-Type": "multipart/form-data",

				"Authorization": token
			},
		};


		const { data } = await axios.put("https://api.lagha.shop/api/me/update", userData, config);

		dispatch({
			type: UPDATE_PROFILE_SUCCESS,
			payload: data.success,
		});
	} catch (error) {
		dispatch({
			type: UPDATE_PROFILE_FAIL,
			payload: error.response.data,
		});
	}
};

// Update password
export const updatePassword = (oldPassword, password) => async (dispatch) => {
	try {
		dispatch({ type: UPDATE_PASSWORD_REQUEST });
		const token = localStorage.getItem('token');

		const config = {
			headers: {
				"Content-Type": "application/json",
				"Authorization": token

			},
		};

		const { data } = await axios.put(
			"https://api.lagha.shop/api/password/update",
			{ oldPassword, password },
			config
		);

		dispatch({
			type: UPDATE_PASSWORD_SUCCESS,
			payload: data.success,
		});
	} catch (error) {
		dispatch({
			type: UPDATE_PASSWORD_FAIL,
			payload: error.response.data,
		});
	}
};

export const sendMessage = (payload) => async (dispatch) => {
	try {
		dispatch({ type: MESSAGE_REQUEST });

		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};

		const { data } = await axios.post("https://api.lagha.shop/api/messages", payload, config);

		dispatch({
			type: MESSAGE_SUCCESS,
			payload: data.message,
		});
	} catch (error) {
		dispatch({
			type: MESSAGE_FAIL,
			payload: error.response.data,
		});
	}
};
// Forgot password
export const forgotPassword = (email) => async (dispatch) => {
	try {
		dispatch({ type: FORGOT_PASSWORD_REQUEST });

		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};

		const { data } = await axios.post("https://api.lagha.shop/api/password/forgot", email, config);

		dispatch({
			type: FORGOT_PASSWORD_SUCCESS,
			payload: data.message,
		});
	} catch (error) {
		dispatch({
			type: FORGOT_PASSWORD_FAIL,
			payload: error.response.data,
		});
	}
};

// Reset password
export const resetPassword = (token, passwords) => async (dispatch) => {
	try {
		dispatch({ type: NEW_PASSWORD_REQUEST });

		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};

		const { data } = await axios.put(
			`https://api.lagha.shop/api/password/reset/${token}`,
			passwords,
			config
		);

		dispatch({
			type: NEW_PASSWORD_SUCCESS,
			payload: data.success,
		});
	} catch (error) {
		dispatch({
			type: NEW_PASSWORD_FAIL,
			payload: error.response.data,
		});
	}
};

// Get all users
export const allUsers = (currentPage) => async (dispatch) => {
	try {
		dispatch({ type: ALL_USERS_REQUEST });
		const token = localStorage.getItem('token');

		const config = {
			headers: {
				"Content-Type": "application/json",
				"Authorization": token

			},
		};
		const { data } = await axios.get(`https://api.lagha.shop/api/admin/users?page=${currentPage}`, config);

		dispatch({
			type: ALL_USERS_SUCCESS,
			payload: data.users,
			pagination: data.pagination
		});
	} catch (error) {
		dispatch({
			type: ALL_USERS_FAIL,
			payload: error.response.data.message,
		});
	}
};

// Update user - ADMIN
export const updateUser = (id, userData) => async (dispatch) => {
	try {
		dispatch({ type: UPDATE_USER_REQUEST });
		const token = localStorage.getItem('token');

		const config = {
			headers: {
				"Content-Type": "application/json",
				"Authorization": token

			},
		};

		const { data } = await axios.put(
			`https://api.lagha.shop/api/admin/users/${id}`,
			userData,
			config
		);

		dispatch({
			type: UPDATE_USER_SUCCESS,
			payload: data.success,
		});
	} catch (error) {
		dispatch({
			type: UPDATE_USER_FAIL,
			payload: error.response.data.message,
		});
	}
};

// Get user details - ADMIN
export const getUserDetails = (id) => async (dispatch) => {
	try {
		dispatch({ type: USER_DETAILS_REQUEST });
		const token = localStorage.getItem('token');

		const config = {
			headers: {
				"Authorization": token

			},
		};
		const { data } = await axios.get(
			`https://api.lagha.shop/api/admin/users/${id}`, config
		);

		dispatch({
			type: USER_DETAILS_SUCCESS,
			payload: data.user,
		});
	} catch (error) {
		dispatch({
			type: USER_DETAILS_FAIL,
			payload: error.response.data.message,
		});
	}
};

// Delete user - ADMIN
export const deleteUser = (id) => async (dispatch) => {
	try {
		dispatch({ type: DELETE_USER_REQUEST });
		const token = localStorage.getItem('token');

		const config = {
			headers: {
				"Authorization": token

			},
		};
		const { data } = await axios.delete(
			`https://api.lagha.shop/api/admin/users/${id}`, config
		);

		dispatch({
			type: DELETE_USER_SUCCESS,
			payload: data.success,
		});
	} catch (error) {
		dispatch({
			type: DELETE_USER_FAIL,
			payload: error.response.data.message,
		});
	}
};

export const newsletterSubscription = (email) => async (dispatch) => {
	try {
		dispatch({ type: SUBSCRIPTION_REQUEST });
		// const token = localStorage.getItem('token');


		const { data } = await axios.post(
			`https://api.lagha.shop/api/subscribe`, { email },
		);

		dispatch({
			type: SUBSCRIPTION_SUCCESS,
			payload: data.success,
		});
	} catch (error) {
		dispatch({
			type: SUBSCRIPTION_FAIL,
			payload: error.response.data.message,
		});
	}
};


// Clear Errors
export const clearErrors = () => async (dispatch) => {
	dispatch({
		type: CLEAR_ERRORS,
	});
};
