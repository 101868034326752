import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { login, clearErrors } from '../../actions/userActions'
import "../../styles/login.css"
import { useTranslation } from 'react-i18next'

const Login = () => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const { t } = useTranslation('auth')

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	const { isAuthenticated, error, loading } = useSelector((state) => state.auth)

	const redirect = location.search ? '/' + location.search.split('=')[1] : '/'

	const submitHandler = (e) => {
		e.preventDefault()
		dispatch(login(email, password))
	}

	useEffect(() => {
		if (isAuthenticated) {
			navigate(redirect)
		}

		if (error && error.message) {
			toast.error(error.message, {
				position: toast.POSITION.TOP_RIGHT,
				className: 'm-2',
			})
		}
	}, [dispatch, isAuthenticated, error, navigate, redirect])

	useEffect(() => {
		dispatch(clearErrors())
	}, [dispatch])

	return (
		<>
			<div className='background-image-container'>
				<span className='login-title'>{t("account")}</span>
				<span className='login-subtitle'>{t("home")} / {t("Account")}</span>
			</div>

			<section className='container my-4'>
				<div className='container py-5 h-100'>
					<div className='row d-flex justify-content-center align-items-center h-100'>
						<div className='col-12 col-md-8 col-lg-6 col-xl-5'>
							<div>
								<div className='card-body p-5 text-center'>


									<form onSubmit={submitHandler}>
										<div className='form-group mb-4'>
											<input
												type='email'
												required
												className='login-input'
												placeholder={t("enter_your_email")}
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>
										</div>

										<div className='form-group'>
											<input
												type='password'
												required
												className='login-input'
												placeholder={t("enter_your_password")}
												value={password}
												onChange={(e) => setPassword(e.target.value)}
											/>
											{error && error.errors && error.errors.password && (
												<small className='form-text text-danger text-left mt-2 mx-1'>
													{error.errors.password}
												</small>
											)}
										</div>

										<div className='text-right mt-1 mb-3'>
											<Link to='/forgot-password' type='submit' className='login-link'>
												{t("forgot_password")}
											</Link>
										</div>

										<button
											className='login-button'
											type='submit'
											disabled={loading ? true : false}
										>
											{loading ? (
												<div
													className='spinner-border'
													role='status'
													style={{ width: '25px', height: '25px' }}
												>
													<span className='sr-only'>{t("loading")}</span>
												</div>
											) : (
												t("login")
											)}
										</button>
									</form>

									<span>
										{t("not_registered_yet")}{' '}
										<Link to='/register' className='login-link'>{t("create_an_account")}</Link>
									</span>



								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

		</>
	)
}

export default Login
